var SCROLL_DOWN_CLASS = 'hide';
var Scroll = /** @class */ (function () {
    function Scroll() {
        var _this = this;
        this.lastScroll = 0;
        this._canScroll = true;
        this.bindEvents = function () {
            var _a;
            window.addEventListener('scroll', _this.handleScroll);
            (_a = document
                .querySelector('#back-to-top')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', _this.handleScrollClick);
        };
        this.handleScrollClick = function () {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        };
        this.handleScroll = function () {
            var currentScroll = window.pageYOffset;
            if (_this._canScroll) {
                if (currentScroll <= 0) {
                    _this.node.classList.remove('with-background');
                    return;
                }
                if (currentScroll > window.innerHeight / 2) {
                    _this.node.classList.add('with-background');
                }
                else if (currentScroll < window.innerHeight / 2) {
                    _this.node.classList.remove('with-background');
                }
                if (currentScroll > _this.lastScroll &&
                    !_this.node.classList.contains(SCROLL_DOWN_CLASS)) {
                    // down
                    _this.node.classList.add(SCROLL_DOWN_CLASS);
                }
                else if (currentScroll <= _this.lastScroll &&
                    _this.node.classList.contains(SCROLL_DOWN_CLASS)) {
                    // up
                    _this.node.classList.remove(SCROLL_DOWN_CLASS);
                }
                _this.lastScroll = currentScroll;
            }
        };
        this.node = document.querySelector('#header');
        this.bindEvents();
        this.handleScrollClick();
    }
    Object.defineProperty(Scroll.prototype, "canScroll", {
        get: function () {
            return this._canScroll;
        },
        set: function (val) {
            this._canScroll = val;
            this.handleScroll();
        },
        enumerable: false,
        configurable: true
    });
    return Scroll;
}());
export { Scroll };
