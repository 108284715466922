import '../scss/index.scss';
import { Header } from './components/Header';
import { Splash } from './components/Splash';
import { Scroll } from './components/Scroll';
import { WIDTHS } from './references/constants';
var idSets = [
    {
        p2: '#g-p2',
        p3: '#g-p3',
        d: '#s-d',
        b: '#s',
    },
    {
        p2: '#s-p2',
        p3: '#s-p3',
        d: '#d-d',
        b: '#d',
    },
];
var MyriadApp = /** @class */ (function () {
    function MyriadApp() {
        var _this = this;
        this.setHeaderCanScroll = function (val) {
            _this.scroll.canScroll = val;
        };
        this.bindEvents = function () {
            window.addEventListener('resize', _this.listBlocksUp);
        };
        this.listBlocksUp = function () {
            idSets.forEach(function (_a) {
                var p2 = _a.p2, p3 = _a.p3, d = _a.d, b = _a.b;
                var p2El = document.querySelector(p2);
                var p3El = document.querySelector(p3);
                var dEl = document.querySelector(d);
                var bEl = document.querySelector(b);
                if (p2El && p3El && dEl && bEl) {
                    if (window.innerWidth >= WIDTHS.desktop) {
                        var p2Height = p2El.getBoundingClientRect().height;
                        var p3Height = p3El.getBoundingClientRect().height;
                        var moveUpBy = p2Height + p3Height;
                        var dividerHeight = dEl.getBoundingClientRect().height;
                        if (d === '#d-d') {
                            dEl.style.marginBottom = moveUpBy - 48 + "px";
                        }
                        else {
                            dEl.style.marginTop = "-" + moveUpBy + "px";
                        }
                        bEl.style.marginTop = "-" + (moveUpBy - dividerHeight - 40) + "px";
                    }
                    else {
                        dEl.style.marginTop = '';
                        bEl.style.marginTop = '';
                    }
                }
            });
        };
        this.scroll = new Scroll();
        this.header = new Header(this.setHeaderCanScroll);
        this.splash = new Splash();
        this.listBlocksUp();
        this.bindEvents();
    }
    return MyriadApp;
}());
window.addEventListener('load', function () {
    // @ts-expect-error no one got time for that
    global.myriadApp = new MyriadApp();
});
