var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { WIDTHS } from '../references/constants';
var Header = /** @class */ (function () {
    function Header(setHeaderCanScroll) {
        var _this = this;
        this.state = {
            menuOpen: false,
        };
        this.bindEvents = function () {
            window.addEventListener('resize', _this.handleNavResize);
            _this.button.addEventListener('click', _this.handleMenuClick);
            document.querySelectorAll('.header__nav-item').forEach(function (node) {
                var _a = __read(node.children, 1), anchor = _a[0];
                anchor.addEventListener('click', _this.handleNavClick);
            });
            _this.nav.addEventListener('transitionend', function () {
                if (!_this.state.menuOpen) {
                    _this.setHeaderCanScroll(true);
                }
            });
        };
        this.handleNavClick = function () {
            var innerWidth = window.innerWidth;
            if (innerWidth < WIDTHS.header) {
                _this.setHeaderCanScroll(false);
                _this.handleMenuClick();
            }
        };
        this.handleMenuClick = function () {
            var menuOpen = _this.state.menuOpen;
            if (!menuOpen) {
                _this.button.classList.add('header__button--close');
                _this.nav.classList.add('header__nav--open');
                document.body.style.overflow = 'hidden';
                document.documentElement.style.overflow = 'hidden';
                _this.state = {
                    menuOpen: true,
                };
            }
            else {
                _this.button.classList.remove('header__button--close');
                _this.nav.classList.remove('header__nav--open');
                document.body.style.overflow = '';
                document.documentElement.style.overflow = '';
                _this.state = {
                    menuOpen: false,
                };
            }
        };
        this.handleNavResize = function () {
            var innerHeight = window.innerHeight, innerWidth = window.innerWidth;
            if (innerWidth < WIDTHS.header) {
                _this.nav.style.height = innerHeight + "px";
            }
            else {
                _this.nav.style.height = 'auto';
            }
        };
        this.nav = document.querySelector('#main-nav');
        this.button = document.querySelector('#open-menu');
        this.setHeaderCanScroll = setHeaderCanScroll;
        this.bindEvents();
        this.handleNavResize();
    }
    return Header;
}());
export { Header };
