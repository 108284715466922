var OFFSET = 20;
var Splash = /** @class */ (function () {
    function Splash() {
        var _this = this;
        this.bindEvents = function () {
            window.addEventListener('resize', _this.handleNavResize);
        };
        this.handleNavResize = function () {
            var innerHeight = window.innerHeight;
            _this.node.style.height = innerHeight + "px";
            var height = _this.wordmark.getBoundingClientRect().height;
            var dividerHeight = (innerHeight - height) / 2;
            _this.dividers.forEach(function (node, i) {
                if (i === 0) {
                    // weird on iphone 7 there's a gap?
                    node.style.height = dividerHeight - OFFSET / 2 + "px";
                }
                else if (i === 1) {
                    node.style.height = dividerHeight + OFFSET + "px";
                }
            });
        };
        this.node = document.querySelector('#splash');
        this.wordmark = document.querySelector('#wordmark');
        this.dividers = [
            document.querySelector('#splash-divider-top'),
            document.querySelector('#splash-divider-bottom'),
        ];
        this.bindEvents();
        this.handleNavResize();
    }
    return Splash;
}());
export { Splash };
